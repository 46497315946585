import React from 'react'
import cx from 'classnames'

/**
 * This simple component serves as a flexible wrapper intended to provide
 * left-and-right margin. Can provide a className to override.
 *
 * NOTE: Keep in mind if using TailwindCSS classes that CSS specificity rules
 * still apply.
 */
export const Container = ({ children, className }) => {
  return (
    <div className={cx('max-w-7xl	px-10 md-px-14 mx-auto', className)}>
      {children}
    </div>
  )
}
