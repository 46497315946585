import React from 'react'
import { Link } from 'gatsby'

import { INTERNAL_URLS } from '../../config/urls'
import { Container } from '../common/Container'

import styles from './Landing.module.scss'
import BackgroundImage_1 from '../images/bg-homepage-1.png'
import BackgroundImage_2 from '../images/bg-homepage-2.png'
import HeroImage from '../images/homepage-hero-img.png'
import HeroImage2x from '../images/homepage-hero-img@2x.png'
import AboutImage from '../images/homepage-about-img.png'
import AboutImage2x from '../images/homepage-about-img@2x.png'

import PrenatalIconImage from '../images/prenatal-icon@2x.png'
import PostnatalIconImage from '../images/postnatal-icon@2x.png'
import GynecologyIconImage from '../images/gynecology-icon@2x.png'
import MenopauseIconImage from '../images/menopause-icon@2x.png'

import ArrowRight from '../svg/ArrowRight'

const Services = () => {
  return (
    <div className={styles.servicesSection}>
      <img
        className={styles.servicesBackground}
        src={BackgroundImage_2}
        alt="VGA background 2"
      />

      <div className={styles.servicesPanes}>
        <div className={styles.servicesPane}>
          <div className={styles.servicesPaneContent}>
            <img
              className={styles.servicesIcon}
              src={GynecologyIconImage}
              alt="Gynecology - Valencia Gynecology Associates"
            />
            <div className={styles.servicesName}>Gynecology</div>
            <div className={styles.servicesDesc}>
              We provide preventative, diagnostic and specialized gynecologic services for women.
            </div>
            <div>
              <Link
                to={INTERNAL_URLS.SERVICES_GYNECOLOGY}
                className={styles.servicesLink}
              >
                Learn More
                <ArrowRight className={styles.servicesLinkArrow} />
              </Link>
            </div>
          </div>
        </div>

        <div className={styles.servicesPane}>
          <div className={styles.servicesPaneContent}>
            <img
              className={styles.servicesIcon}
              src={PrenatalIconImage}
              alt="Prenatal - Valencia Gynecology Associates"
            />
            <div className={styles.servicesName}>
              Prenatal Care & Obstetrics
            </div>
            <div className={styles.servicesDesc}>
              Our obstetrical services include pre-pregnancy, pregnancy and post-pregnancy care plans.
            </div>
            <div>
              <Link
                to={INTERNAL_URLS.SERVICES_PRENATAL}
                className={styles.servicesLink}
              >
                Learn More
                <ArrowRight className={styles.servicesLinkArrow} />
              </Link>
            </div>
          </div>
        </div>

        <div className={styles.servicesPane}>
          <div className={styles.servicesPaneContent}>
            <img
              className={styles.servicesIcon}
              src={PostnatalIconImage}
              alt="Postnatal - Valencia Gynecology Associates"
            />
            <div className={styles.servicesName}>Postnatal Care</div>
            <div className={styles.servicesDesc}>
              We will work to make sure that you and your new baby remain as healthy as possible.
            </div>
            <div>
              <Link
                href={INTERNAL_URLS.SERVICES_POSTNATAL}
                className={styles.servicesLink}
              >
                Learn More
                <ArrowRight className={styles.servicesLinkArrow} />
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.servicesPane}>
          <div className={styles.servicesPaneContent}>
            <img
              className={styles.servicesIcon}
              src={MenopauseIconImage}
              alt="Menopause - Valencia Gynecology Associates"
            />
            <div className={styles.servicesName}>Menopause & Beyond</div>
            <div className={styles.servicesDesc}>
              We offer personalized care as your body transitions through the phases of menopause.
            </div>
            <div>
              <Link
                to={INTERNAL_URLS.SERVICES_MENOPAUSE}
                className={styles.servicesLink}
              >
                Learn More
                <ArrowRight className={styles.servicesLinkArrow} />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.servicesAllNavigation}>
        <Link to={INTERNAL_URLS.SERVICES}>
          View All Services
          <ArrowRight className={styles.servicesLinkArrow} />
        </Link>
      </div>
    </div>
  )
}

const AboutUs = () => {
  return (
    <div className={styles.aboutSection}>
      <div className={styles.aboutSectionIn}>
        <img
          className={styles.aboutSectionImage}
          src={AboutImage}
          srcSet={`${AboutImage2x} 2x`}
          alt="About Us - Valencia Gynecology Associates"
        />
        <div className={styles.aboutSectionContent}>
          <div className={styles.aboutSectionBorder} />
          <h2 className={styles.aboutSectionHeader}>You’re in good hands.</h2>
          <div className={styles.aboutSectionCopy}>
            <p className={styles.aboutSectionCopyParagraph}>
              Whether you’re new to womanhood or beyond menopause, we’re here
              with you and always strive to create a comfortable, caring
              environment.
            </p>
            <p className={styles.aboutSectionCopyParagraph}>
              Valencia Gynecology Associates have been serving Santa Clarita
              residents for over 20 years, offering a wide variety of
              obstetrical and gynecological services. In addition to routine
              prenatal care, well-women exams and gynecological surgery, we also
              address the medical issues concerning teens and menopausal women.
            </p>
            <p className={styles.aboutSectionCopyParagraph}>
              Our health care providers and staff are committed to treat each
              patient with dignity, respect, kindness and courtesy. In meeting
              our patient’s needs we believe that every woman is special in her
              own way.
            </p>
            <p className={styles.aboutSectionCopyParagraph}>
              Whether it’s for a routine exam or a extensive surgery, we hope to
              be attending to your health soon in the best way possible. If you
              have any questions, please do not hesitate to reach out.
            </p>
          </div>
          <div className={styles.aboutSectionBtns}>
            <Link className={styles.aboutSectionBtn} to={INTERNAL_URLS.CONTACT}>
              Make an Appointment
            </Link>
            <Link
              className={styles.aboutSectionStaffLink}
              to={INTERNAL_URLS.PROVIDERS}
            >
              Meet Our Providers
              <ArrowRight className={styles.aboutSectionLinkArrow} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export const Landing = () => {
  return (
    <div className={styles.page}>
      <section>
        <img
          className={styles.headerImg}
          src={BackgroundImage_1}
          alt="VGA background"
        />
        <div className={styles.heroBgContainer}>
          <img
            className={styles.headerHeroImg}
            src={HeroImage}
            srcSet={`${HeroImage2x} 2x`}
            alt="VGA Hero"
          />
          <div className={styles.heroSection}>
            <h1 className={styles.heroHeader}>
              Specializing in Obstetrics & Gynecology for over 25 years.
            </h1>
            <h3 className={styles.heroSubheader}>
              Providing the best medical care for every aspect of a woman’s
              health — every step of the way.
            </h3>
          </div>
        </div>
      </section>

      <section className={styles.servicesSectionContainer}>
        <Container>
          <Services />
        </Container>
      </section>

      <section className={styles.aboutSectionContainer}>
        <Container>
          <AboutUs />
        </Container>
      </section>
    </div>
  )
}
