import React from 'react'

import Layout from '../components/layout'
import { Landing } from '../components/Landing'

export const LandingPage = () => (
  <Layout>
    <Landing />
  </Layout>
)

export default LandingPage
